<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <h4>{{ name }}</h4>
    <v-row :dense="dense">
      <v-col>
        Per continuare, è necessario caricare i dati tramite un tracciato XLS o
        CSV.
        <a v-if="templateUrl" :href="templateUrl">
          Scarica file da compilare
        </a>
      </v-col>
      <v-col>
        <v-file-input
          v-model="file"
          filled
          :prepend-icon="icon"
          :rules="fileRules"
          :clearable="clearable"
          :loading="loading"
          show-size
          accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-vnd.oasis.opendocument.spreadsheet"
          @change="parseFile"
        />
      </v-col>
    </v-row>

    <v-alert v-if="error" type="warning">{{ error }}</v-alert>

    <v-data-table :headers="headers" :items="rows" :items-per-page="15">
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteRow(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { read, utils } from 'xlsx'
import { upperFirst } from 'lodash/string.js'
import { mapKeys, mapValues } from 'lodash'

export default {
  name: 'FormUploadExcel',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-file-excel',
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 12,
    },
    md: {
      type: [String, Number],
      default: 12,
    },
    lg: {
      type: [String, Number],
      default: 12,
    },
    xl: {
      type: [String, Number],
      default: 12,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array, Number, Boolean],
      default: null,
    },
    requiredKeys: {
      type: Array,
      default() {
        return []
      },
    },
    templateUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    file: null,
    fileRules: [
      (value) =>
        !value ||
        value.size < 1000000 ||
        'Il file non può essere più grande di  1MB',
    ],
    error: null,
    loading: false,
    columns: [],
    rows: [],
  }),
  computed: {
    headers() {
      return [...this.columns, { text: '', value: 'actions', sortable: false }]
    },
  },

  methods: {
    cleanUpData: function() {
      this.rows = []
      this.columns = []
      this.$emit('input', this.rows)
    },
    async parseFile(file) {
      try {
        if (!file) {
          this.error = null
          this.cleanUpData()
          return
        }

        if (file.size > 1000000)
          throw new Error('Il file non può essere più grande di  1MB')

        this.loading = true
        const workBook = read(await this.file.arrayBuffer())
        const sheet = workBook.SheetNames?.[0]

        this.rows = utils
          .sheet_to_json(workBook.Sheets[sheet])
          .map((row) => mapKeys(row, (value, key) => String(key).trim()))
          .map((row) => mapValues(row, (value) => String(value).trim()))

        if (!this.rows.length) throw new Error('Il file non contiene dati')

        this.validateHeaders()

        this.$emit('input', this.rows)
      } catch (err) {
        console.warn('[parseFile] Error', err)
        this.error = err.message
        this.cleanUpData()
      } finally {
        this.loading = false
      }
    },
    validateHeaders() {
      const keys = Object.keys(this.rows[0])

      if (this.requiredKeys.some((r) => !keys.includes(r.toLowerCase())))
        throw new Error('Il file non contiene tutte le colonne necessarie.')

      this.columns = keys.map((key) => ({
        value: key,
        text: upperFirst(key),
      }))
    },
    async deleteRow(item) {
      const confirm = await this.$dialog.confirm({
        title: 'Cancellare elemento',
        text: 'Si desidera eliminare la riga selezionata?',
      })
      if (!confirm) return

      this.rows.splice(this.rows.indexOf(item), 1)
      this.$emit('input', this.rows)
    },
  },
}
</script>

<style scoped></style>

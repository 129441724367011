<template>
  <v-container fluid>
    <v-card outlined tile>
      <v-card-title> Crea Nuovo Ticket </v-card-title>
      <v-card-text>
        <TicketsForm @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import TicketsForm from '@components/tickets/TicketsForm.vue'

export default {
  name: 'DealerCreate',
  page: {
    title: 'Creazione Ticket',
  },
  components: { TicketsForm },
  created() {
    this.reset()
  },
  methods: {
    ...mapMutations('tickets', {
      reset: 'RESET_CURRENT',
    }),
    onSubmitted(id) {
      this.$router.push({ name: 'tickets_detail', params: { id } })
      this.reset()
    },
  },
}
</script>

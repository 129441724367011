<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <v-dialog v-model="display" :width="dialogWidth">
      <template v-slot:activator="{ on }">
        <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
          <v-text-field
            :clearable="clearable"
            :dense="dense"
            :disabled="disabled"
            :error-messages="errors"
            :hint="hint"
            :label="label || name"
            :loading="loading"
            :persistent-hint="persistentHint"
            :prefix="prefix"
            :suffix="suffix"
            :value="formattedDatetime"
            append-icon="event"
            filled
            readonly
            type="text"
            v-on="on"
          >
            <template v-slot:progress>
              <slot name="progress">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  absolute
                  height="2"
                ></v-progress-linear>
              </slot>
            </template>
          </v-text-field>
        </ValidationProvider>
      </template>

      <v-card>
        <v-card-text class="px-0 py-0">
          <v-tabs v-model="activeTab" fixed-tabs>
            <v-tab key="calendar">
              <slot name="dateIcon">
                <v-icon>event</v-icon>
              </slot>
            </v-tab>
            <v-tab key="timer" :disabled="dateSelected">
              <slot name="timeIcon">
                <v-icon>access_time</v-icon>
              </slot>
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker
                v-model="date"
                v-bind="datePickerProps"
                full-width
                @input="showTimePicker"
              ></v-date-picker>
            </v-tab-item>
            <v-tab-item key="timer">
              <v-time-picker
                ref="timer"
                v-model="time"
                format="24hr"
                class="v-time-picker-custom"
                :allowed-hours="(h) => h > 6 && h < 22"
                :allowed-minutes="(m) => m % 5 === 0"
                v-bind="timePickerProps"
                full-width
              ></v-time-picker>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="actions" :parent="this">
            <v-btn color="grey lighten-1" text @click.native="clearHandler">{{
              clearText
            }}</v-btn>
            <v-btn color="green darken-1" text @click="okHandler">{{
              okText
            }}</v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { format, parse } from 'date-fns'

const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT = 'Annulla'
const DEFAULT_OK_TEXT = 'OK'

export default {
  name: 'FormDatetimePicker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    clearText: {
      default: DEFAULT_CLEAR_TEXT,
      type: String,
    },

    cols: {
      type: [String, Number],
      default: 6,
    },
    sm: {
      type: [String, Number],
      default: null,
    },
    md: {
      type: [String, Number],
      default: null,
    },
    lg: {
      type: [String, Number],
      default: null,
    },
    xl: {
      type: [String, Number],
      default: null,
    },
    dateFormat: {
      default: DEFAULT_DATE_FORMAT,
      type: String,
    },
    datePickerProps: {
      type: Object,
    },
    datetime: {
      default: null,
      type: [Date, String],
    },
    dense: {
      default: false,
      type: Boolean,
    },
    dialogWidth: {
      default: DEFAULT_DIALOG_WIDTH,
      type: Number,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    loading: {
      type: Boolean,
    },
    name: {
      default: 'Data e ora',
      type: String,
    },
    okText: {
      default: DEFAULT_OK_TEXT,
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    prefix: {
      default: null,
      required: false,
      type: String,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    suffix: {
      default: null,
      required: false,
      type: String,
    },
    textFieldProps: {
      type: Object,
    },
    timeFormat: {
      default: 'HH:mm',
      type: String,
    },
    timePickerProps: {
      type: Object,
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    }
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + ' ' + this.timeFormat
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? format(this.selectedDatetime, this.dateTimeFormat)
        : ''
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time
        if (this.time.length === 5) {
          datetimeString += ':00'
        }
        return parse(datetimeString, this.defaultDateTimeFormat, new Date())
      } else {
        return null
      }
    },
    dateSelected() {
      return !this.date
    },
  },
  watch: {
    datetime: function() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.datetime) {
        return
      }

      let initDateTime
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime
      } else if (
        typeof this.datetime === 'string' ||
        this.datetime instanceof String
      ) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
      }

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT)
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT)
    },
    okHandler() {
      this.resetPicker()
      this.$emit('input', format(this.selectedDatetime, this.dateTimeFormat))
    },
    clearHandler() {
      this.resetPicker()
      this.date = DEFAULT_DATE
      this.time = DEFAULT_TIME
      this.$emit('input', null)
    },
    resetPicker() {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
    },
  },
}
</script>
